<template>
  <div>
    <appliance-dish-form :save="save" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApplianceDishForm from './appliance-dish-form.vue'

export default {
  name: 'ApplianceDishEdit',
  components: { ApplianceDishForm },
  async created() {
    document.title = 'Редактирование товара'
    const { id } = this.$route.params
    this.fetchById(id)
  },
  methods: {
    async save(image) {
      await this.$store.dispatch('customDishes/form/update', image)
      await this.$router.push({ name: 'present-dish-list' })
    },
    ...mapActions('customDishes/form', ['fetchById']),
  },
}
</script>
